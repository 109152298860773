import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/cvpp-chapter-fe/cvpp-chapter-fe/apps/goodnews-re100-consumer/src/app/providers/modal-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryClientProvider"] */ "/home/runner/work/cvpp-chapter-fe/cvpp-chapter-fe/apps/goodnews-re100-consumer/src/app/providers/react-query-client-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toast"] */ "/home/runner/work/cvpp-chapter-fe/cvpp-chapter-fe/apps/goodnews-re100-consumer/src/components/molecules/Toast/Toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/home/runner/work/cvpp-chapter-fe/cvpp-chapter-fe/node_modules/.pnpm/@vercel+analytics@1.5.0_next@14.2.3_react@18.3.1/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/cvpp-chapter-fe/cvpp-chapter-fe/node_modules/.pnpm/next@14.2.3_@babel+core@7.26.0_@playwright+test@1.46.0_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../assets/fonts/WantedSansVariable.woff2\",\"display\":\"swap\",\"preload\":true}],\"variableName\":\"wantedSansFont\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/cvpp-chapter-fe/cvpp-chapter-fe/apps/goodnews-re100-consumer/src/globals.css");
